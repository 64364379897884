body, #container {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-flow__edge-path {
  stroke: black !important;
}

#extendsArrow polyline {
  fill: var(--color-background);
  stroke: var(--color-text-default);
}

#associationArrow polyline {
  stroke: var(--color-text-default);
  fill: none;
}

.react-flow__edge-textbg {
  fill: var(--color-background) !important;
}

.react-flow__edge-text {
  fill: var(--color-text-default) !important;
}

.react-flow__edge-path {
  stroke: var(--color-text-default) !important;
}

.edge-node {
  background: var(--color-background);
  text-align: center;
}

.tooltip-inner {
  max-width: 350px !important;
  /* If max-width does not work, try using width instead */
  width: 350px !important;
}

.attribute-link, .association-link {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.attribute-row:hover .attribute-link, .association-row:hover .association-link {
  opacity: 1;
}

.attribute-text {
  margin-left: 1rem;
  text-indent: -1rem;
}

