@media (prefers-color-scheme: dark) {

    :root {
        --color-background: #111;
        --color-background-footer: #222;
        --color-border: rgba(255, 255, 255, 0.3);
        --color-text-default: white;
        --color-text-inverted: #222;
        --color-text-accent: #3bb5dc;
        --color-text-accent-inverted: white;
        --color-base: #057fa544;
    }

    html.bg-light {
        background-color: var(--color-background-footer) !important;
    }

    .text-muted {
        color: var(--color-text-default);
    }

    li.filter-button:hover, li.filter-button:active{
        background-color: var( --color-text-accent);
        color: var(--color-text-inverted);
    }

    button.navbar-toggler {
        background-color: var(--color-background-footer);
    }

    .paginator button.dropdown-item {
        text-decoration: underline;
    }

    .paginator button.dropdown-item:hover {
        text-decoration: none;
    }

    .badge-info, .bg-info, a.badge-info.btn:hover {
        border: 1px solid var(--color-text-accent);
        background-color: var(--color-background-footer) !important;
        color: var(--color-text-default) !important;
    }

    .modal-content {
        background-color: #303030;
    }

    .btn-secondary {
        color: #fff;
        background-color: #444;
        border-color: #444;
    }

    .btn-secondary:hover {
        color: #fff;
        background-color: #313131;
        border-color: #2b2a2a;
    }

    .dropdown-item {
        color: var(--color-text-default);
    }

    .paginator .page-item.active a.page-link {
        background-color: var(--color-text-default);
    }

}
