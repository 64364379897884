
:root {
    --color-background: white ;
    --color-border: rgba(0,0,0,.125);
    --color-background-footer: #fff;
    --color-text-default: black ;
    --color-text-inverted: white ;
    --color-text-accent: #057fa5 ;
    --color-text-accent-inverted: black ;
    --color-base: #057fa5 ;
}
